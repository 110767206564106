<template>
    <div>
        <KidHeader :serialNumber="serialNumber" :style="{ 'max-width': '575px', 'margin': '0 auto', 'padding-left': '15px', 'padding-right': '15px' }" />
        <div class="container-fluid">
			<div class="main-container" v-if="categoryDetail">
                <div class="profile-section mb-2">
                    <div class="pl-2">
                        <span>
                            <a class="text-black" href="javascript:void(0)" @click="$router.push({ path: '/kids-device'})"><i class="fas fa-long-arrow-alt-left"></i>&nbsp; <b>Dashboard</b></a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="profile-section" style="border-bottom: unset;max-width:575px;margin:0 auto;" v-if="categoryDetail">
            <div class="card w-100 background-dark" :style="{ 'border-radius': 'unset'}">
                <div class="card-body">
                    <img src="https://cdn-icons-png.flaticon.com/512/2603/2603910.png" style="height: 40px; width: 40px;">
                    <span class="pl-2"><b>{{ categoryDetail.category }}</b></span>
                    <span class="float-right pt-2">
                        <small v-if="isCategoryEnabled == true">Enabled &nbsp;</small> 
                        <small v-else>Disabled &nbsp;</small> 
                        <label class="switch">
                            <input type="checkbox" v-model="isCategoryEnabled" @change="toggleCategoryStatus()">
                            <span class="slider round"></span>
                        </label>
                    </span>
                </div>
            </div>
        </div>
        <div class="container-fluid">
			<div class="main-container" v-if="categoryDetail">
                <div class="profile-section" :style="{'border-bottom': 'unset'}">
                    <p class="text-black"><b>Daily Limit</b> - <small class="text-black">In Minute</small></p>
                    <div class="card w-100 background-dark">
                        <div class="card-body pl-1" :style="{ 'padding': '3px', 'padding-top': '8px', 'padding-bottom': 'unset' }">
                            <span class="pl-1" :style="{ 'font-size': '15px' }">Enabled daily limit</span>
                            <span class="float-right" :style="{ 'padding-top': '2px' }">
                                <label class="switch">
                                    <input type="checkbox" v-model="enableDailyLimit">
                                    <span class="slider round"></span>
                                </label>
                            </span>
                        </div>
                    </div>
                    <div class="pt-2 pb-4">
                        <label class="pl-1">Set daily limit
                            <a href="javascript:void(0)" @click="openDailyLimitPopup()" class="text-black"><i class="fas fa-info-circle"></i></a>
                        </label>
                        <div class="float-right w-50">
                            <div class="input-group" :style="{ 'width': '95%', 'padding-right': '1px', 'border': '1px solid #dee2e6' }">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" :style="{ 'background-color': 'white', 'border': 'unset' }" id="btnGroupAddon">
                                        <a class="text-black" href="javascript:void(0)" @click="decreaseLimit()">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect y="24" width="24" height="24" rx="12" transform="rotate(-90 0 24)" fill="#F2F2F2"/>
                                                <path d="M13.6667 15.3333L10.4003 12.157C10.311 12.0703 10.311 11.9296 10.4003 11.8429L13.6667 8.66663" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <input type="text" class="form-control text-black text-center" :style="{ 'border': 'unset', 'font-weight': 'bold' }" :disabled="enableDailyLimit==false" v-model="dailyLimitTime" @blur="roundOF15()" :class="{ 'text-warning': categoryDetail.isDeliveredToDevice == false, 'text-success': categoryDetail.isDeliveredToDevice == true }">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" :style="{ 'background-color': 'white', 'border': 'unset' }" id="btnGroupAddon">
                                        <a class="text-black" href="javascript:void(0)" @click="increaseLimit()">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="#F2F2F2"/>
                                                <path d="M10.3333 8.66671L13.5997 11.843C13.689 11.9297 13.689 12.0704 13.5997 12.1571L10.3333 15.3334" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!--<input type="number" :disabled="enableDailyLimit==false" step=15 class="form-control" v-model="dailyLimitTime" />
                            <small class="text-muted">In Minute.</small>-->
                        </div>
                    </div>
                </div>
                <div class="profile-section text-center p-3">
                    <button type="button" class="btn btn-primary" :style=" { 'background': '#0073EE', 'border-radius': '20px', 'width': '30%' }" @click="updateCategoryDailyLimit()">Save</button>
                </div>
                <div class="profile-section" :style="{ 'border': 'unset' }">
                    <p class="text-black"><b>Activity</b></p>
                    <div class="btn-group btn-group-justified nav nav-pills justify-content-center" role="tablist" >
                        <a class="btn btn-light nav-link w-50 active" :style="{ 'text-decoration': 'none', 'padding': '4px', 'background-color': '#F7F7F7' }" href="javascript:void(0)" role="tab" data-toggle="tab" @click="changeFilterType('Day')">Day</a>
                        <a class="btn btn-light nav-link w-50" :style="{ 'text-decoration': 'none', 'padding': '4px', 'background-color': '#F7F7F7' }" href="javascript:void(0)" role="tab" data-toggle="tab" @click="changeFilterType('Week')">Week</a>
                    </div>
                    <div class="pt-3" :style="{ 'padding-right': '2px'}">
                        <div class="input-group border">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" :style="{ 'background-color': 'white', 'border-left': 'unset','border-top': 'unset', 'border-bottom': 'unset' }" id="btnGroupAddon">
                                        <a class="text-black" href="javascript:void(0)" @click="previousDate()">
                                            <!--<i class="fas fa-chevron-circle-left"></i>-->
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect y="24" width="24" height="24" rx="12" transform="rotate(-90 0 24)" fill="#F2F2F2"/>
                                                <path d="M13.6667 15.3333L10.4003 12.157C10.311 12.0703 10.311 11.9296 10.4003 11.8429L13.6667 8.66663" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                        </a>
                                    </div>
                                </div>
                                <div class="form-control text-center" :style="{ 'border': 'unset', 'font-weight': 'bold' }">{{ this.formattedDate }}</div>
                                <div class="input-group-prepend">
                                    <div class="input-group-text" :style="{ 'background-color': 'white', 'border-left': 'unset','border-top': 'unset', 'border-bottom': 'unset' }" id="btnGroupAddon">
                                        <a class="text-black" href="javascript:void(0)" @click="nextDate()">
                                            <!--<i class="fas fa-chevron-circle-right"></i>-->
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="#F2F2F2"/>
                                                <path d="M10.3333 8.66671L13.5997 11.843C13.689 11.9297 13.689 12.0704 13.5997 12.1571L10.3333 15.3334" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                        </a>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                
                <div class="profile-section" style="border-bottom: unset;">
                    <h6 class="text-black">
                        <b>Category Screen Time: </b>
                        <span class="text-primary"><b>{{ totalUsedTime }}</b> </span>
                    </h6>
                    <!--<span class="text-primary"><b :style="{ 'font-size': '30px' }">{{ totalUsedTime }}</b> <small class="text-black"> / {{ categoryDetail.dailyLimitTimeFormat }}</small></span>-->
                    
                </div>

                <div class="profile-section" style="border-bottom: unset;">
                    <div class="card" :style="{ 'border': 'unset' }">
                        <div class="card-body" :style="{ padding: 'unset' }">
                            <CanvasJSChart v-if="mostUsedApplicationList.length > 0"  :options="options" :styles="styleOptions"/>
                            <div v-else>
                                <img v-if="filterType == 'Day'" :src="dayNoDataGraph" class="img-fluid" />
                                <img v-else :src="weekNoDataGraph" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="profile-section" :style="{'border-bottom': 'unset'}">
                    <h6 class="text-black"><b>Most Used Apps In This Category</b></h6>
                    <div class="row border-bottom p-3" v-for="application in mostUsedApplicationList" :key="application._id">
                        <div class="col-xs-2" v-if="application.playStoreDetail">
                            <img :src="application.playStoreDetail.icon" style="height: 35px; width: 35px;border-radius: 10px;">
                        </div>&nbsp;
                        <div class="col-xs-10" :style="{ 'width': '48%' }">
                            <a href="javascript:void(0)" :style="{ 'font-size': '14px' }" @click="$router.push({ path: '/application', query: { package_name: application.packageName, profile_id: this.profileId, serialNumber: this.serialNumber } })" class="text-black">
                                <span v-if="application.playStoreDetail">{{ application.playStoreDetail.title }}
                                    <label v-if="!application.dailyLimit"> - <b>{{ application.timeInMinuteFormatted }}</b></label>
                                </span>
                                <span v-else>{{ application.packageName }}
                                    <small v-if="!application.dailyLimit"> - {{ application.timeInMinuteFormatted }}</small>
                                </span>
                            </a>
                            <div>
                                <div class="progress" v-if="application.dailyLimit">
                                    <div class="progress-bar" :class="{'bg-danger': application.timeInMinute > application.dailyLimit }" role="progressbar" :style="{ width: application.progressBarPercentage + '%' }" aria-valuemax="100">
                                        <span v-if="application.dailyLimit" class="category-detail-progressbar"><b>{{ application.timeInMinuteFormatted }}</b> / {{ application.dailyLimitFormatted }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2" :style="{ 'width': '36%', 'text-align': 'right', 'padding-top': '5px' }">
                            <a href="javascript:void(0)" :style="{ 'font-size': '14px' }" @click="$router.push({ path: '/application', query: { package_name: application.packageName, profile_id: this.profileId } })" class="text-black">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.33331 2.66671L10.5595 7.74878C10.7024 7.88747 10.7024 8.11261 10.5595 8.2513L5.33331 13.3334" stroke="#737373" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div class="row border-bottom p-3" v-for="application in notUsedApplicationList" :key="application._id">
                        <div class="col-xs-2" v-if="application.playStoreDetail">
                            <img :src="application.playStoreDetail.icon" style="height: 35px; width: 35px;border-radius: 10px;">
                        </div>&nbsp;
                        <div class="col-xs-10" :style="{ 'width': '48%' }">
                            <a href="javascript:void(0)" :style="{ 'font-size': '14px' }" @click="$router.push({ path: '/application', query: { package_name: application.package_name, profile_id: this.profileId, serialNumber: this.serialNumber } })" class="text-black">
                                <span v-if="application.playStoreDetail">{{ application.playStoreDetail.title }}</span>
                                <span v-else>{{ application.package_name }}</span>
                            </a>
                        </div>
                        <div class="col-xs-2" :style="{ 'width': '36%', 'text-align': 'right', 'padding-top': '5px' }">
                           <a href="javascript:void(0)" :style="{ 'font-size': '14px' }" @click="$router.push({ path: '/application', query: { package_name: application.package_name, profile_id: this.profileId } })" class="text-black">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.33331 2.66671L10.5595 7.74878C10.7024 7.88747 10.7024 8.11261 10.5595 8.2513L5.33331 13.3334" stroke="#737373" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                           </a>
                        </div>
                    </div>
                    <p v-if="mostUsedApplicationList.length == 0 && notUsedApplicationList.length == 0">
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8 19.4627C15.8 21.5049 16.6112 23.4634 18.0553 24.9074C19.4993 26.3514 21.4578 27.1627 23.5 27.1627C25.5422 27.1627 27.5007 26.3514 28.9447 24.9074C30.3887 23.4634 31.2 21.5049 31.2 19.4627C31.2 17.4205 30.3887 15.462 28.9447 14.018C27.5007 12.5739 25.5422 11.7627 23.5 11.7627C21.4578 11.7627 19.4993 12.5739 18.0553 14.018C16.6112 15.462 15.8 17.4205 15.8 19.4627Z" stroke="#737373" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M34.4998 30.4625L28.9456 24.9067" stroke="#737373" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M34.5 12.8626C34.5002 11.8514 34.3012 10.85 33.9143 9.91571C33.5274 8.98141 32.9602 8.1325 32.2452 7.41745C31.5301 6.7024 30.6812 6.13523 29.7469 5.74833C28.8126 5.36144 27.8112 5.16241 26.8 5.1626H9.2C7.15783 5.1626 5.19931 5.97385 3.75528 7.41788C2.31125 8.86191 1.5 10.8204 1.5 12.8626C1.5 14.9048 2.31125 16.8633 3.75528 18.3073C5.19931 19.7513 7.15783 20.5626 9.2 20.5626H11.4" stroke="#737373" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        No data to show
                    </p>
                </div>

                <div class="profile-section text-center pt-2">
                        Last Update: {{ lastUpdated }}
                </div>
                <div class="profile-section mb-2" >
                    <div class="collapse footer" id="dailyLimitPopup">
                        <div class="card">
                            <div class="card-body">
                                <h6 class="text-black">
                                    <b>Daily Limit</b> <a href="javascript:void(0)" class="text-black" @click="closeDailyLimitPopup()"><i class="fas fa-times float-right pt-1 pr-2"></i></a>
                                </h6><hr>
                                <p class="text-black">
                                  <ul>
                                    <li><b>Orange:</b> Time limit sent to device</li>
                                    <li><b>Green:</b> Time limit successfully applied to device</li>
                                  </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <KidFooter />
            </div>
		</div>
    </div>
</template>
<script>
import KidHeader from '@/components/KidHeader.vue'
import KidsService from "../../services/KidsService";
import ProfileService from "../../services/ProfileService";
import KidFooter from '@/components/KidFooter.vue'
let options = { weekday: 'short', month: 'long', day: 'numeric' };

export default {
  name: 'AppDetail',
  components: {
    KidHeader,
    KidFooter
  },
  props: {
    dayNoDataGraph: {
        type: String,
        default: "./images/day-nodata-graph.png",
    },
    weekNoDataGraph: {
        type: String,
        default: "./images/week-nodata-graph.png",
    },
  },
  data() {
    return {
      id: this.$route.query.id,
      profileId: this.$route.query.profileId,
      serialNumber: this.$route.query.serialNumber,
      categoryDetail: null,
      mostUsedApplicationList: [],
      notUsedApplicationList: [],

      enableDailyLimit: false,
      dailyLimitTime: 0,
      isCategoryEnabled: true,

        currentDate: new Date(),
        formattedDate: new Date().toLocaleDateString("en-US", options),
        options: null,
        styleOptions: {
            width: "100%",
            height: "250px"
        },
        lastUpdated: "No data found.",
        deviceDetail: null,
        filterType: "Day",
        weekType: "Current",
        totalUsedTime: 0,
    }
  },
  methods: {
    async getCategoryDetail() {
        let data = {
            id: this.id,
        }
        await KidsService.getCategoryDetail(data)
            .then((response) => {
                this.categoryDetail = response.data.data;
                this.dailyLimitTime = (this.categoryDetail.dailyLimitTime) ? this.categoryDetail.dailyLimitTime : 0;
                this.enableDailyLimit = this.categoryDetail.enableDailyLimit;
                this.isCategoryEnabled = this.categoryDetail.isCategoryEnabled;
                this.getDeviceProfileMostUsedApplicationList();
                this.getCategoryDetailStackedChart();
            }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            });
    },
    async getDeviceProfileMostUsedApplicationList() {
      let data = {
        profileId: this.profileId,
        serialNumber: this.serialNumber,
        category: this.categoryDetail.category,
        filterType: this.filterType,
        weekType: this.weekType,
        currentDate: this.currentDate,
      }
      await KidsService.getDeviceProfileMostUsedApplicationList(data)
        .then((response) => {
            this.mostUsedApplicationList = response.data.data;
            this.notUsedApplicationList = response.data.notUsedApps;
            this.totalUsedTime = response.data.totalUsedTime
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    updateCategoryDailyLimit() {
      if (this.enableDailyLimit == true && ! this.dailyLimitTime) {
        return this.$toast.error("Please enter time.");  
      }
      if (this.enableDailyLimit == true && this.dailyLimitTime < 0) {
        return this.$toast.error("Please enter time in positive.");  
      }
      if (this.dailyLimitTime % 15 != 0 ) {
        return this.$toast.error("Please enter time multiple of 15.");   
      }
      
      let data = {
        id: this.id,
        dailyLimitTime: this.dailyLimitTime,
        enableDailyLimit: this.enableDailyLimit,
        isCategoryEnabled: this.isCategoryEnabled,
        serialNumber: this.serialNumber
      }
      KidsService.updateCategoryDailyLimit(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.applyPolicy();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    applyPolicy() {
      this.isDisabled = true;
      let data = {
        profile_id: this.profileId
      };

      ProfileService.applyPolicy(data)
        .then((response) => {
          this.isDisabled = false;
        })
        .catch((e) => {
          this.isDisabled = false;
          //this.$toast.error(e.response.data.respones_msg);
        });
    },

    async getCategoryDetailStackedChart() {
        let data = {
        profileId: this.profileId,
        serialNumber: this.serialNumber,
        playStoreCategory: this.categoryDetail.category,
        currentDate: this.currentDate,
        filterType: this.filterType,
        weekType: this.weekType
      }
      await KidsService.getCategoryDetailStackedChart(data)
        .then((response) => {
            this.options = response.data.data
            this.deviceDetail = response.data.deviceDetail;
            if (this.deviceDetail.lastDeviceActivationDate) {
                this.lastUpdated = new Date(this.deviceDetail.lastDeviceActivationDate).toLocaleDateString("en-US", options) + " " + new Date(this.deviceDetail.lastDeviceActivationDate).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
            } else {
                this.lastUpdated = "No data found.";
            }
        }).catch((e) => {
          this.$toast.error(e);
        });
    },
    changeFilterType(type) {
        this.filterType = type;
        if (this.filterType == "Day") {
            this.formattedDate = this.currentDate.toLocaleDateString("en-US", options);
        } else {
            this.formattedDate = "This Week";
            this.weekType = "Current";
        }
    },
    previousDate() {
        if (this.filterType == "Day") {
            let day = this.currentDate.getTime() - (1 * 24 * 60 * 60 * 1000);
            this.currentDate.setTime(day);
            this.formattedDate = this.currentDate.toLocaleDateString("en-US", options);
        } else {
            this.formattedDate = "Previous Week"
            this.weekType = "Previous";
        }
        
        this.getCategoryDetailStackedChart();
        this.getDeviceProfileMostUsedApplicationList();
    },
    nextDate() {
        if (this.filterType == "Day") {
            let day = this.currentDate.getTime() + (1 * 24 * 60 * 60 * 1000);
            this.currentDate.setTime(day);
            this.formattedDate = this.currentDate.toLocaleDateString("en-US", options)
        } else {
            this.formattedDate = "This Week",
            this.weekType = "Current";    
        }
        
        this.getCategoryDetailStackedChart();
        this.getDeviceProfileMostUsedApplicationList();
    },
    toggleCategoryStatus() {
        if (this.enableDailyLimit == true && this.categoryDetail.usedTime >= this.dailyLimitTime) {
            this.isCategoryEnabled = false;
            return this.$toast.error("Please increase daily limit time to enable this category.");  
        }
      let data = {
            id: this.id,
            dailyLimitTime: this.dailyLimitTime,
            enableDailyLimit: this.enableDailyLimit,
            isCategoryEnabled: this.isCategoryEnabled,
            serialNumber: this.serialNumber
        }
        KidsService.updateCategoryDailyLimit(data)
        .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.applyPolicy();
        })
        .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    decreaseLimit() {
        if (this.dailyLimitTime > 0) {
            this.dailyLimitTime = this.dailyLimitTime - 15
        }
    },
    increaseLimit() {
        this.dailyLimitTime = (parseInt(this.dailyLimitTime) + 15)
    },
    roundOF15() {
        this.dailyLimitTime = Math.ceil(this.dailyLimitTime/15.0) * 15;
    },
    openDailyLimitPopup() {
        $('#dailyLimitPopup').collapse('show')
    }, 
    closeDailyLimitPopup(){
        $('#dailyLimitPopup').collapse('hide')
    },
  },
  watch: {
    enableDailyLimit() {
        if (this.enableDailyLimit == false) {
          this.dailyLimitTime = 0
        }
    },
    dailyLimitTime() {
        if (this.dailyLimitTime < 0) {
            this.dailyLimitTime = 0;
        }
    },
    filterType() {
        this.getCategoryDetailStackedChart();
        this.getDeviceProfileMostUsedApplicationList();
    }
  },
  beforeMount() {
    this.getCategoryDetail();
  }
}
</script>